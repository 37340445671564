import axios from "@/axios"

// Credit Pack API's

export async function getCreditPacks() {
    const url = "/tenant-credit-pack/clients"
    return await axios.get(url)
}
export async function getClientCreditPack(credit_pack_id) {
    const url = `/tenant-credit-pack/clients/${credit_pack_id}`
    return await axios.get(url)
}
export async function getTenantUserCreditPack(credit_pack_id) {
    const url = `/tenant-credit-allocation/get-credit-pack-details/${credit_pack_id}`
    return await axios.get(url)
}
export async function assignCreditPack(payload) {
    const url = `/tenant-credit-pack/client`
    return await axios.post(url, payload)
}
export async function getAssignedCreditPack(base_credit_pack_id) {
    const url = `/tenant-credit-pack/${base_credit_pack_id}/clients`
    return await axios.get(url)
}
export async function getClients(tenant_id) {
    const url = `/user/tenant/${tenant_id}`
    return await axios.get(url)
}
export async function getCurrencies() {
    const url = "/currency/master"
    return await axios.get(url)
}
export async function creditAndSourceList(params) {
    const url = `nrn?${params}`;
    return await axios.get(url);
}
export async function getclientId(payload) {
    const url = `tenant/clients`
    return await axios.post(url, payload)
}

// Inventory API's

export async function getCreditPackInventory(tenant_id, params) {
    const url = `/credit-pack-inventory/tenant/credit_pack_inventory?tenant_id=${tenant_id}&${params}`
    return await axios.get(url)
}
export async function activateCreditPack(credit_pack_id) {
    const url = `credit-pack-inventory/tenant/activate-credit-pack/${credit_pack_id}`
    return await axios.get(url)
}
export async function getCreditAlloted(credit_pack_id, params) {
    const url = `/credit-pack-inventory/credit_pack_alloted?credit_pack_id=${credit_pack_id}&${params}`
    return await axios.get(url)
}
export async function getCreditUsage(credit_pack_id, params) {
    const url = `/credit-pack-inventory/credit_pack_usage?credit_pack_id=${credit_pack_id}&${params}`
    return await axios.get(url)
}
export async function getQuery(query) {
    const url = `source-consume-callback/get_query_data?ref_id=${query}`
    return await axios.get(url)
}

// credit allocation API's

export async function getCreditPacksAllocationList() {
    const url = "/tenant-credit-allocation/get-allocated-credit-pack-list"
    return await axios.get(url)
}
export async function getUsers(tenant_id) {
    const url = `/user/tenant/${tenant_id}`
    return await axios.get(url)
}
export async function getCreditInventory() {
    const url = "tenant-credit-allocation/get-inventory-credit-pack-list"
    return await axios.get(url)
}
export async function createCreditInventory(payload) {
    const url = "tenant-credit-allocation/allocate-credit-to-user"
    return await axios.post(url, payload)
}
export async function getUserAssigneCreditPackList(credit_pack_id) {
    const url = `tenant-credit-allocation/get-user-list-assigned-to-credit-pack/${credit_pack_id}`
    return await axios.get(url)
}
export async function updatePack(payload) {
    const url = `/tenant-credit-allocation/update-credit-to-user`
    return await axios.put(url,payload)
}
export async function deleteCredits(credit_pack_id, current_user_id) {
    const url = `tenant-credit-allocation/${credit_pack_id}/delete-user/${current_user_id}`
    return await axios.delete(url)
}